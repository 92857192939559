import { cssBundleHref } from "@remix-run/css-bundle";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
"@remix-run/react";
import type { LinksFunction, LoaderFunctionArgs, SerializeFrom } from "partymix";
import { json } from "partymix";

import type { PlaybackState } from "@spotify/web-api-ts-sdk";
import rdtCss from "remix-development-tools/index.css";

import { createSpotifyStrategy } from "~/auth";
import { Spotify } from "~/spotify/context";
import { createSpotifyApi } from "~/utils/spotify";
import type { UserData } from "../party/user";
import User from "../party/user";
import globalCss from "./global.css";
import tailwindCss from "./tailwind.css";

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: globalCss },
{ rel: "stylesheet", href: tailwindCss },
...(process.env.NODE_ENV === "development" ?
[{ rel: "stylesheet", href: rdtCss }] :
[]),
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];


// PartyKit will inject the host into the server bundle
// so let's read it here and expose it to the client
declare const PARTYKIT_HOST: string;
export async function loader(args: LoaderFunctionArgs) {
  const strategy = createSpotifyStrategy(args);
  const session = await strategy.getSession(args.request);

  let playbackState: PlaybackState | undefined;
  if (session) {
    const api = createSpotifyApi(args.context.env.SPOTIFY_CLIENT_ID, session);

    playbackState = await api.player.getPlaybackState();
  }

  let data: UserData | undefined;
  if (session?.user) {
    const res = await args.context.lobby.parties.user.
    get(session.user.id).
    fetch({
      headers: {
        [User.idHeader]: session.user.id
      }
    });

    if (res.ok) {
      data = await res.json();
    } else {
      console.error(res.statusText, res.status);
    }
  }

  return json({
    partykitHost: PARTYKIT_HOST,
    user: session?.user,
    data,
    playbackState
  });
}

export type RootData = SerializeFrom<typeof loader>;

function App() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Spotify>
          <Outlet />
        </Spotify>
        <ScrollRestoration />
        <Scripts />
        {process.env.NODE_ENV === "development" && <LiveReload />}
      </body>
    </html>);

}

let AppExport = App;
if (process.env.NODE_ENV === "development") {
  const { withDevTools } = await import("remix-development-tools");
  AppExport = withDevTools(AppExport);
}

export default AppExport;